<template>
  <div class="app-updates">
    <v-card
      v-for="(item, index) in list"
      :key="item.id"
      class="mb-5 elevation-1"
      :class="{ 'grey lighten-3': item.id <= previousVersion }"
    >
      <v-card-title
        :class="{
          secondary:
            (previousVersion !== null && item.id > previousVersion) ||
            index === 0,
        }"
      >
        {{ item.version }}
      </v-card-title>
      <v-card-text class="pt-3">
        <strong><rs-table-cell-date :value="item.released_on" /></strong>
        <div class="pt-3">
          <ul class="text-lg">
            <li class="mb-2" v-for="(v, k) in item.description" :key="k">
              {{ v }}
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hasDataTable } from "../../mixins";

export default {
  name: "AppUpdates",
  mixins: [hasDataTable],
  computed: {
    ...mapGetters(["newVersion", "version"]),
  },
  data() {
    return {
      previousVersion: null,
      list: [],
    };
  },
  methods: {
    loadList() {
      this.$api
        .query("app-updates")
        .then((response) => {
          const list = this.list;

          response.data.data.forEach((item) => {
            list.push(item);
          });

          this.list = list;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
  mounted() {
    if (this.$route.query.previous_version) {
      this.previousVersion = parseInt(this.$route.query.previous_version);
    } else if (this.newVersion) {
      const url = new URL(window.location.href);
      url.searchParams.set("previous_version", this.version);
      window.location.href = url.toString();
      return;
    }

    this.loadList();
  },
};
</script>

<style>
.app-updates ul li {
  font-size: 1.2em;
}
</style>
